<template>
  <div class="hotel-information">
    <h3 class="sub-title">Hotel Information</h3>
    <div class="sub-tishi">Due to the large number of attendees, please book your own accommodation. There are hotels in the vicinity.</div>
    <div class="container">
      <div class="title">INTERCONTINENTAL WUHAN</div>
      <h4>LIVE AN INTERCONTINENTAL LIFE</h4>
      <p>
        Travel not simply for material comfort or enjoyment in haste;<br>
        They travel for discovery and experience.<br>
        We are glad to share our International knowledge<br>
        Wherever we are and deliver authentic experiences for you<br>
        by offering premium service with distinct style,<br>
        to connect our guest to all that is special about our destinations<br>
        and live an InterContinental life.
      </p>
      <div class="img-box">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/01/60b179759e9b476189cd3adae134ba8b.png" alt="">
      </div>
      <h4>
        IN THE KNOW WUHAN
      </h4>
      <p>
        Holding a bowl of noodles, watching people exercise nearby,<br>
        feeling the momentum of the Yangtze River,<br>
        every breath is enjoyable in the Wuhan morning.<br>
        This is a city of history, culture and beauty,<br>
        where you can visit historic Yellow Crane Tower scenic area and Luomeixuan,<br>
        a place to listen to beautiful music brought forward a thousand years. What’s more, no trip to Wuhan is complete without taking a moment to gaze upon the luminous Wuhan First Yangtze Bridge at night.
      </p>
      <div class="img-box">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/01/9375b0b8820243c8948b6321d7eb7498.png" alt="">
      </div>
      <h4>INTERCONTINENTAL WUHAN</h4>
      <p>
        InterContinental Wuhan is inspired by water, and refreshes the city’s skyline with its gently curved shape. As the first luxury international five-star hotel in the area, the hotel, which stands integrated with 12 symmetrical exhibition buildings that make up Wuhan International Expo Center, is a captivating area landmark.
      </p>
      <div class="img-box">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/01/f7da3acc5ba448d3bcec2ef6f641f6e9.png" alt="">
      </div>
      <h4>GRAND BALLROOM</h4>
      <p>
        The top floor comprising a vast 6,000sqm pillar-less Grand Ballroom. <br>
        Flexible  to  be partitioned  to six  individual meeting rooms.<br>
        Comfortably accommodates up to 4,000 guests for meeting or banquet.<br>
        State-of-the-art audio visual equipment and spacious foyer.<br>
      </p>
      <div class="img-box-s">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/01/21199af0c5f047058363f7824867a918.png" alt="">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/01/93d4fc49f5934ad18c8c1498b033c6f4.png" alt="">
      </div>
      <h4>Grace Select Hotel (Wuhan International Expo Center)
        <br>格雷斯精选酒店(武汉国博中心店)
      </h4>
      <p>Address: Building 3, Greenland Guobo Plaza (Phase II), 111 North Sixin Road, Hanyang District.
        <br>地址：汉阳区四新北路111号绿地国博广场（二期）3号楼
      </p>
      <div class="img-box">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/10/3a536e2a70e44bbc93f9513cd85b69ee.png" alt="">
      </div>
      
      <h4>Mobo Hotel (Wuhan International Expo Center Sixin North Road Branch)
        <br>雲泊酒店(武汉国际博览中心四新北路店)
      </h4>
      <p>Address: First floor, Building 35, Commercial building, Liyue Island Home, 328 North Sixin Road, Hanyang District.
        <br>地址：汉阳区四新北路328号鲤鱼洲家园商业楼35栋一楼
      </p>
      <div class="img-box">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/10/82f7b66a8e25450c8230dd3996089390.png" alt="">
      </div>
      
      <h4>Kaiyuan Manju · Wuhan International Expo Center
        <br>开元曼居·武汉国际博览中心
      </h4>
      <p>Address: Floor 25-29, Block B, Greenland Wealth Center, 111 Sixin North Road, Hanyang District.
        <br>地址：汉阳区四新北路111号绿地财富中心B座25-29层
      </p>
      <div class="img-box">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/10/1d2f3a859d3944e8b2af5662b1179049.png" alt="">
      </div>
      
      <h4>Atour Hotel International Expo Center Sixin Wuhan
        <br>武汉国际博览中心四新亚朵酒店
      </h4>
      <p>Address: No. 26, Block B, Liyue Island Home Business, North Sixin Road, Hanyang District.
        <br>地址：汉阳区四新北路鲤鱼洲家园商业B座26号
      </p>
      <div class="img-box">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/10/cfc23c332d2745938cb57d3869d402cc.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.hotel-information {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin-top: 30px;
  }
  .sub-tishi {
    font-family: "PublicSans Regular";
    border-bottom: solid 1px #ddd;
    color: #888;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .container {
    font-family: "PublicSans Regular";
    font-size: 16px;
    .title {
      background: linear-gradient(73deg, rgba(63,136,163,1) 30%, rgba(88,46,104,1) 100%);
      text-align: center;
      color: #fff;
      font-size: 24px;
      margin-bottom: 20px;
      font-family: "Akrobat Black";
      h3 {
        padding: 8px;
        color: #fff;
      }
    }
    h4 {
      font-family: "Akrobat Black";
      font-weight: 700;
      position: relative;
      margin: 35px 0 15px;
      padding-left: 15px;
      border-left: 3px solid #e8bd55;
      font-size: 20px;
      line-height: 28px;
    }
    p {
      margin-bottom: 20px;
    }
    .img-box {
      margin: 0 auto;
      max-width: 600px;
      width: 80%;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      margin-bottom: 20px;
      img {
        max-width: 100%;
      }
    }
    .img-box-s {
      margin: 0 auto;
      max-width: 800px;
      width: 100%;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      img {
        max-width: 45%;
      }
    }
  }
}
</style>
